$(function () {
    initNav();
    initMatchheight();
    initPopup();
    initHeaders();
    initCarousel();
    initSlides();
    initAfspraak();
    initReferences();
    homeCarousel();
});

let enabled = false;

$('header #mobile-nav-button').unbind().click(function () {
    $('header').toggleClass('toggle');
    $('#nav-button-wrapper').toggleClass('gold-border')
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        $nav.children('ul').attr('style', false);
        initMatchheight();
    }, 200));
});

function initNav() {
    $btn_navigation = $('header .nav-toggle');
    $nav = $('header nav');

    $btn_navigation.click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 980) {
            $nav.slideToggle();
        }
    });

    if ($nav.find('ul').length > 0) {
        $li = $nav.find('li');

        $li.has('ul').children('a').click(function (e) {

            $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle');

                    if ($(window).width() < 1024) {
                        $(ul).slideUp();
                    }
                }
            });

            if ($(this).next('ul').css('visibility') === 'hidden' || $(this).next('ul').is(':hidden')) {
                e.preventDefault();

                if ($(window).width() < 1024) {
                    $(this).next('ul').slideDown();
                }
                $(this).children('span').addClass('toggle');
            }
        });
    }
}


function homeCarousel() {
    $.ajax({
        url: '/get-image-urls',
        success: function(imageUrls) {
            var nextImage = 5;
            var previousElement = null;
            var totalImages = imageUrls.length;

            function getRandomInt(currentElement) {
                if (window.matchMedia('only screen and (max-width: 600px)').matches) {
                    var maxInt = 1;
                }else{
                    var maxInt = 4;
                }
                var randomInt =  Math.floor(Math.random() * maxInt);
                if(currentElement === randomInt){
                    return getRandomInt(currentElement);
                }
                return randomInt;
            }

            function update() {
                currentElement = getRandomInt(previousElement);
                if (!window.matchMedia('only screen and (max-width: 600px)').matches) {
                    previousElement = currentElement;
                }

                var newImage = $('.img-container .new').eq(currentElement);
                var oldImage = $('.img-container .old').eq(currentElement);
                var newImageUrl = imageUrls[nextImage];

                // Put new image into new container
                newImage.attr('src', newImageUrl);

                // Fade new image to opacity 1
                newImage.fadeTo(50, 1, function(){
                    oldImage.attr('src', newImageUrl);
                    newImage.removeAttr('style');
                });

                nextImage++;
                currentElement++;

                if(nextImage === totalImages){
                    nextImage = 1;
                }
            }

            setInterval(update, 2000);
        }
    });
}


function initMatchheight() {
    $('.matchheight').each(function () {
        $(this).find('.match').matchHeight({
            byRow: false
        });
    });
}

function initSponsors() {
    if ($('#sponsors').length) {
        $('.sponsors').slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            prevArrow: '<i class="fa fa-chevron-left arrow prev"></i>',
            nextArrow: '<i class="fa fa-chevron-right arrow next"></i>',

            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
}


function initAfspraak() {
    if ($('#afspraak').length) {
        $('.afspraakSlider').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            prevArrow: $('#afspraak .swiper-button-prev'),
            nextArrow: $('#afspraak .swiper-button-next'),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }
}

function initReferences() {
    if ($('#references').length) {
        $('.referenceSlider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: $('#references .swiper-button-prev'),
            nextArrow: $('#references .swiper-button-next'),
        });
    }
}

function initPopup() {
    $('.popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        }
    });
}

function initCarousel() {
    if ($('#carousel').length > 0) {
        $('#carousel').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 5000
        });
    }
}

function initHeaders() {
    if ($('#headers').length > 0) {
        $('#headers').slick({
            slidesToShow: 1,
            fade: true,
            cssEase: 'linear',
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 3000
        });
    }
}

function initSlides() {
    $('#bruidegom-slide').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: true,
        // lazyLoad: 'ondemand',
        infinite: true,
        autoplaySpeed: 2000
    });
}

var map;

function initMap() {
    let location = {lat: 51.900950, lng: 4.162688};
    map = new google.maps.Map(document.getElementById('map'), {
        center: location,
        zoom: 7
    });

    var marker = new google.maps.Marker({
        position: location,
        map: map,
        title: 'Bruidsmoederjurk'
    });
}
